import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';

import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/libs/i18n'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(TawkMessengerVue, {
    propertyId : '63f5e32131ebfa0fe7eea9bb',
    widgetId : '1gq9h1k1b'
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
